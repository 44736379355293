/* Diseño base para móviles */
body {
    min-width: 320px; /* Mínimo recomendado para móviles */
    font-family: Arial, sans-serif;
}

/* Punto de ruptura para tabletas */
@media (min-width: 768px) {
    body {
        min-width: 768px;
    }
}

/* Punto de ruptura para computadoras de escritorio */
@media (min-width: 1024px) {
    body {
        min-width: 1024px;
    }
}


.custom-card-view {
    border-radius: 7px;
    padding: 10px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}

.custom-card-view .is-child-card {
    background-color: #f0f0f0;
}

.custom-card-row {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    margin-block: 5px;
    cursor: auto;
}

.my-custom-cell {
    border-right: black 1px solid;
    border-top: black 1px solid;
}

.swalfire{
    z-index: 99999999999;
}