
.background {
    /* background-color: var(--bs-primary); */
    /* background-image: url("../../../assets/img/wall1.webp"); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.header {
    height: 3rem;
}

.box-login {
    background-color: var(--bs-gray-100);
    border-radius: 5px;
}

@font-face {
    font-family: 'Atmospheric';
    src: local('Atmospheric'), url(../../../assets/fonts/aAtmospheric.otf) format('opentype');
}

.title-cala {
    font-family: "Atmospheric";
}