.card-view {
    border-radius: 20px;
    padding: 10px;
    background-color: #efefef;
    box-shadow:  0px 3px 10px 1px rgba(0, 0, 0, 0.5);
}

.card-row {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-block: 10px;
    cursor:auto;
}