.yellowRow {
  background-color: #FFFDE7;
}

.yellowRow.Mui-selected {
  background-color: #fcf9d0 !important;
}
.yellowRow:hover {
  background-color: #fcf9d0 !important;
}

.textBold {
  font-weight: bold;
}