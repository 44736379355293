.custom {
    border: none !important;
    width: 110%;
    margin-inline: -7px;
    background-color: transparent;
}

.first-level{
    background-color: rgba(94, 94, 94, 0.27)
}

.open {
    animation: openMenu 0.3s linear;
}

@keyframes openMenu {
    0% {
        transform: translateY(-0.2rem);
        opacity: 0%;
    }


    50% {
        transform: translateY(-0.1rem);
        opacity: 35%;
    }
    

    100% {
        transform: translateY(0);
        opacity: 100%;
    }
}