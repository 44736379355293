.item-accordion {
    padding: 1rem;
    border-width: 1px solid var(--bs-primary);
    border-top-style: 10px;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.button-more {
    background-color: transparent;
    border: none;
    width: 5rem;
    height: 2rem;
}

.item-accordion:hover {
    /* background-color: #cfe3f3; */
    background-color: #efefef;
    color: #2C3333;
}

.open {
    animation: openMenu 0.3s ease-in-out;
}

@keyframes openMenu {
    0% {
        transform: translateX(2rem);
    }

    100% {
        transform: translateX(0);
    }
}