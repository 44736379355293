.box-login{
    background-color: var(--bs-gray-100);
    border-radius: 20px;
}

@font-face {
    font-family: 'Atmospheric';
    src: local('Atmospheric'), url(../../../assets/fonts/aAtmospheric.otf) format('opentype');
}

.title-cala{
    font-family: "Atmospheric";
}